import Head from 'next/head';
import { useRouter } from 'next/router';

import { getCanonicalURL } from '../utility';

export default function CanonicalURL() {
  const router = useRouter();
  return (
    <Head>
      <link rel="canonical" href={getCanonicalURL(router)} />
    </Head>
  );
}
