import Image from 'next/future/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import HeaderFooterContext from '@/context/HeaderFooterContext';
import pageNot from '@/public/images/error_404.png';
import SetBrandsContext from '@/services/home/setBrands';
import { BRAND_NAME_EN, BRAND_NAME_FR } from '@/utils/constants';

const PageNotFound = () => {
  const { locale }: any = useRouter();

  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;

  const isContext = !!headerFooter[0];

  const getContextData = async () => {
    let defaultBrand = BRAND_NAME_EN;
    if (locale === 'fr') defaultBrand = BRAND_NAME_FR;
    const contextData = await SetBrandsContext(defaultBrand, locale);
    const brands = contextData?.data;
    headerFooterData.setHeaderFooterData(brands);
  };
  useEffect(() => {
    getContextData();
  }, [isContext, locale]);

  return (
    <div className="sv-page-content error-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-9 col-sm-12 mx-auto">
            <div className="error-brief">
              <div className="error-img">
                <Image
                  sizes="(max-width: 375px) 220px, (max-width: 576px) 395px, (max-width: 768px) 400px, (max-width: 992px) 400px, (max-width: 1280px) 400px"
                  src={pageNot}
                  alt="404"
                  className="img-fluid"
                  height="300"
                  width="300"
                />
              </div>
              <h2 className="error-heading">
                <FormattedMessage id="pnf-msg" />!
              </h2>
              <p className="para">
                <FormattedMessage id="pnf-desc" />
              </p>
              <Link href="/self-storage" prefetch={false}>
                <button type="button" className="btn sv-primary-red-btn">
                  <FormattedMessage id="pnf-find" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
